<template>
	<div
		:class="[
			'c-block-list-kultu-naut-custom',
			'c-block-list-kultu-naut-custom--solid',
			'c-block-list-kultu-naut-custom--wide',
			'-mx-layout-margin  mb-0',
			'pl-layout-margin py-lg',
			{ 'bg-sand': template !== 'FrontPage' },
		]"
	>
		<PostScribeScript
			v-if="kultuNautScript && kultuNautScript.script && !demoMode"
			:html="kultuNautScript.script"
		/>
		<div v-if="demoMode">
			<BaseH2 class="text-primary-button mb-sm" v-text="'Det sker'" />
			<SwiperWrap
				:move-by="1"
				class="relative w-full -mr-layout-margin"
				:items-wrapper-class-names="[
					'c-block-list-news-list-custom__scroller',
					'flex w-full u-hide-scrollbar',
					'snap snap-x snap-mandatory',
					'space-x-layout-gutter overflow-x-auto',
					'pb-sm',
					'snap-px-layout-margin',
				]"
			>
				<!-- Items -->
				<template #items>
					<KultunautCard
						v-for="(item, index) in items"
						:key="`kultu-naut-${index}`"
						:name="item.name"
						:to="item.url"
						:target="item.target"
						:class="[
							'c-block-list-kultu-naut-custom__item',
							'flex-shrink-0 w-3col',
							'snap-start h-full',
							'<1024:w-4col',
						]"
						:image="{
							...(item.image || {}),
							aspectRatio: 1.5,
						}"
						light
					>
						<template #before>
							<div class="flex items-center text-12 w-full">
								<SvgCalendarIcon class="w-16 h-16 mr-8" />
								<span class="mr-24">{{ item.date }}</span>
								<SvgClockIcon class="w-14 h-14 mr-8" />
								<span>{{ item.time }}</span>
							</div>
						</template>
						<template #after>
							<div
								class="
									flex flex-col
									items-start
									w-full
									text-body
								"
							>
								<span class="font-semibold">Sted</span>
								<span class="text-14">{{ item.location }}</span>
								<span class="font-semibold mt-xs">Pris</span>
								<span class="text-14">{{ item.price }}</span>
							</div>
						</template>
					</KultunautCard>
				</template>

				<!-- Pagination -->
				<template #before="{ showPagination, go }">
					<div
						:class="[
							'absolute -top-md right-layout-margin',
							'transform -translate-y-full',
							'flex items-end  <768:hidden',
						]"
					>
						<!-- All News -->
						<NuxtLinkExt
							:to="'/'"
							class="uppercase font-semibold text-h7 mb-2 mr-md"
							v-text="'SE ALLE ARRANGEMENTER'"
						/>

						<!-- Previous -->
						<button
							:aria-disabled="!showPagination.previous"
							aria-label="Gå til forrige nyhed"
							:class="[
								'p-8 mr-xs',
								'duration-300 ease-smooth-out',
								'text-text hover:text-primary-button',
								{ 'opacity-20': !showPagination.previous },
							]"
							@click="() => go.previous(false)"
						>
							<SvgCaret class="w-12 transform rotate-180" />
						</button>

						<!-- Next -->
						<button
							:aria-disabled="!showPagination.next"
							aria-label="Gå til næste nyhed"
							:class="[
								'p-8',
								'duration-300 ease-smooth-out',
								'text-text hover:text-primary-button',
								{ 'opacity-20': !showPagination.next },
							]"
							@click="() => go.next(false)"
						>
							<SvgCaret class="w-12" />
						</button>
					</div>
				</template>
			</SwiperWrap>

			<!-- All News -->
			<NuxtLinkExt
				:to="'/'"
				class="
					block
					uppercase
					font-semibold
					text-body
					mt-md
					>=768:hidden
				"
				v-text="'SE ALLE ARRANGEMENTER'"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import PostScribeScript from '~/citi-baseline/components/PostScribeScript';
import SwiperWrap from '~/citi-baseline/components/SwiperWrap';
import KultunautCard from '~/components/shared/KultunautCard';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';
import SvgCalendarIcon from '~/assets/svgs/calendar-icon.svg?inline';
import SvgClockIcon from '~/assets/svgs/clock-icon.svg?inline';
export default {
	name: 'BlockListKultuNautCustom',
	components: {
		PostScribeScript,
		SwiperWrap,
		KultunautCard,
		SvgCaret,
		SvgCalendarIcon,
		SvgClockIcon,
	},
	inheritAttrs: false,

	props: {
		kultuNautScript: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		const { demo } = this.$route.query;
		const demoMode = demo !== undefined;
		return {
			demoMode,
			items: [
				{
					image: {
						width: 6720,
						height: 4480,
						cropUrl:
							'/media/0pfle5us/image-01.jpg?rnd=132974457460970000?width=6720&height=4480&rnd=2034116917',
						altText: 'Img01 Alt tag',
						id: 1161,
						url: '/media/0pfle5us/image-01.jpg',
						imageText: '<p>Beskrivende tekst til billedet.</p>',
						imageCredits: 'Fotograf: A',
						hasLocalCrops: false,
						fileType: 'Image',
					},
					date: '10.11.21 ',
					time: '10.00',
					name: 'Hyggeklubben',
					location: 'Vemmelev Sognelade Fruegade 19, Vemmelev',
					price: 'Gratis',
					url: '/',
				},
				{
					image: {
						width: 6720,
						height: 4480,
						cropUrl:
							'/media/0pfle5us/image-01.jpg?rnd=132974457460970000?width=6720&height=4480&rnd=2034116917',
						altText: 'Img01 Alt tag',
						id: 1161,
						url: '/media/0pfle5us/image-01.jpg',
						imageText: '<p>Beskrivende tekst til billedet.</p>',
						imageCredits: 'Fotograf: A',
						hasLocalCrops: false,
						fileType: 'Image',
					},
					date: '10.11.21 ',
					time: '10.00',
					name: 'Hyggeklubben',
					location: 'Vemmelev Sognelade Fruegade 19, Vemmelev',
					price: 'Gratis',
					url: '/',
				},
				{
					image: {
						width: 6720,
						height: 4480,
						cropUrl:
							'/media/0pfle5us/image-01.jpg?rnd=132974457460970000?width=6720&height=4480&rnd=2034116917',
						altText: 'Img01 Alt tag',
						id: 1161,
						url: '/media/0pfle5us/image-01.jpg',
						imageText: '<p>Beskrivende tekst til billedet.</p>',
						imageCredits: 'Fotograf: A',
						hasLocalCrops: false,
						fileType: 'Image',
					},
					date: '10.11.21 ',
					time: '10.00',
					name: 'Hyggeklubben',
					location: 'Vemmelev Sognelade Fruegade 19, Vemmelev',
					price: 'Gratis',
					url: '/',
				},
				{
					image: {
						width: 6720,
						height: 4480,
						cropUrl:
							'/media/0pfle5us/image-01.jpg?rnd=132974457460970000?width=6720&height=4480&rnd=2034116917',
						altText: 'Img01 Alt tag',
						id: 1161,
						url: '/media/0pfle5us/image-01.jpg',
						imageText: '<p>Beskrivende tekst til billedet.</p>',
						imageCredits: 'Fotograf: A',
						hasLocalCrops: false,
						fileType: 'Image',
					},
					date: '10.11.21 ',
					time: '10.00',
					name: 'Hyggeklubben',
					location: 'Vemmelev Sognelade Fruegade 19, Vemmelev',
					price: 'Gratis',
					url: '/',
				},
				{
					image: {
						width: 6720,
						height: 4480,
						cropUrl:
							'/media/0pfle5us/image-01.jpg?rnd=132974457460970000?width=6720&height=4480&rnd=2034116917',
						altText: 'Img01 Alt tag',
						id: 1161,
						url: '/media/0pfle5us/image-01.jpg',
						imageText: '<p>Beskrivende tekst til billedet.</p>',
						imageCredits: 'Fotograf: A',
						hasLocalCrops: false,
						fileType: 'Image',
					},
					date: '10.11.21 ',
					time: '10.00',
					name: 'Hyggeklubben',
					location: 'Vemmelev Sognelade Fruegade 19, Vemmelev',
					price: 'Gratis',
					url: '/',
				},
				{
					image: {
						width: 6720,
						height: 4480,
						cropUrl:
							'/media/0pfle5us/image-01.jpg?rnd=132974457460970000?width=6720&height=4480&rnd=2034116917',
						altText: 'Img01 Alt tag',
						id: 1161,
						url: '/media/0pfle5us/image-01.jpg',
						imageText: '<p>Beskrivende tekst til billedet.</p>',
						imageCredits: 'Fotograf: A',
						hasLocalCrops: false,
						fileType: 'Image',
					},
					date: '10.11.21 ',
					time: '10.00',
					name: 'Hyggeklubben',
					location: 'Vemmelev Sognelade Fruegade 19, Vemmelev',
					price: 'Gratis',
					url: '/',
				},
			],
		};
	},
	computed: mapState({
		template: (state) => state.template,
	}),
};
</script>
