var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-kultu-naut-custom',
		'c-block-list-kultu-naut-custom--solid',
		'c-block-list-kultu-naut-custom--wide',
		'-mx-layout-margin  mb-0',
		'pl-layout-margin py-lg',
		{ 'bg-sand': _vm.template !== 'FrontPage' } ]},[(_vm.kultuNautScript && _vm.kultuNautScript.script && !_vm.demoMode)?_c('PostScribeScript',{attrs:{"html":_vm.kultuNautScript.script}}):_vm._e(),_vm._v(" "),(_vm.demoMode)?_c('div',[_c('BaseH2',{staticClass:"text-primary-button mb-sm",domProps:{"textContent":_vm._s('Det sker')}}),_vm._v(" "),_c('SwiperWrap',{staticClass:"relative w-full -mr-layout-margin",attrs:{"move-by":1,"items-wrapper-class-names":[
				'c-block-list-news-list-custom__scroller',
				'flex w-full u-hide-scrollbar',
				'snap snap-x snap-mandatory',
				'space-x-layout-gutter overflow-x-auto',
				'pb-sm',
				'snap-px-layout-margin' ]},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('KultunautCard',{key:("kultu-naut-" + index),class:[
						'c-block-list-kultu-naut-custom__item',
						'flex-shrink-0 w-3col',
						'snap-start h-full',
						'<1024:w-4col' ],attrs:{"name":item.name,"to":item.url,"target":item.target,"image":Object.assign({}, (item.image || {}),
						{aspectRatio: 1.5}),"light":""},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('div',{staticClass:"flex items-center text-12 w-full"},[_c('SvgCalendarIcon',{staticClass:"w-16 h-16 mr-8"}),_vm._v(" "),_c('span',{staticClass:"mr-24"},[_vm._v(_vm._s(item.date))]),_vm._v(" "),_c('SvgClockIcon',{staticClass:"w-14 h-14 mr-8"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.time))])],1)]},proxy:true},{key:"after",fn:function(){return [_c('div',{staticClass:"\n\t\t\t\t\t\t\t\tflex flex-col\n\t\t\t\t\t\t\t\titems-start\n\t\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\t\ttext-body\n\t\t\t\t\t\t\t"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Sted")]),_vm._v(" "),_c('span',{staticClass:"text-14"},[_vm._v(_vm._s(item.location))]),_vm._v(" "),_c('span',{staticClass:"font-semibold mt-xs"},[_vm._v("Pris")]),_vm._v(" "),_c('span',{staticClass:"text-14"},[_vm._v(_vm._s(item.price))])])]},proxy:true}],null,true)})})},proxy:true},{key:"before",fn:function(ref){
					var showPagination = ref.showPagination;
					var go = ref.go;
return [_c('div',{class:[
						'absolute -top-md right-layout-margin',
						'transform -translate-y-full',
						'flex items-end  <768:hidden' ]},[_c('NuxtLinkExt',{staticClass:"uppercase font-semibold text-h7 mb-2 mr-md",attrs:{"to":'/'},domProps:{"textContent":_vm._s('SE ALLE ARRANGEMENTER')}}),_vm._v(" "),_c('button',{class:[
							'p-8 mr-xs',
							'duration-300 ease-smooth-out',
							'text-text hover:text-primary-button',
							{ 'opacity-20': !showPagination.previous } ],attrs:{"aria-disabled":!showPagination.previous,"aria-label":"Gå til forrige nyhed"},on:{"click":function () { return go.previous(false); }}},[_c('SvgCaret',{staticClass:"w-12 transform rotate-180"})],1),_vm._v(" "),_c('button',{class:[
							'p-8',
							'duration-300 ease-smooth-out',
							'text-text hover:text-primary-button',
							{ 'opacity-20': !showPagination.next } ],attrs:{"aria-disabled":!showPagination.next,"aria-label":"Gå til næste nyhed"},on:{"click":function () { return go.next(false); }}},[_c('SvgCaret',{staticClass:"w-12"})],1)],1)]}}],null,false,1719855736)}),_vm._v(" "),_c('NuxtLinkExt',{staticClass:"\n\t\t\t\tblock\n\t\t\t\tuppercase\n\t\t\t\tfont-semibold\n\t\t\t\ttext-body\n\t\t\t\tmt-md\n\t\t\t\t>=768:hidden\n\t\t\t",attrs:{"to":'/'},domProps:{"textContent":_vm._s('SE ALLE ARRANGEMENTER')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }